import {colors} from "@c10h/colors";
import {isEqual} from "lodash";
import {useCallback, useEffect, useState} from "react";

import useScroll, {ScrollEvent} from "../../../useScroll";
import {transparent} from "../../../utils/transparent";
import {useIsMobile} from "src/utils/isMobile";

export const useHasBeenScrolled = (y: number): boolean => {
  const [hasBeenScrolled, setHasBeenScrolled] = useState(false);

  useEffect(() => {
    if (!hasBeenScrolled) {
      setHasBeenScrolled(y > 0);
    }
  }, [y]);

  return hasBeenScrolled;
};

const THRESHOLD = -5; // scroll value when nav will disappear
const OFFSET = 30; // subtracted from threshold to get value when theme changes to default

export enum NavTheme {
  LIGHT = "light",
  BLACK = "black",
  DEFAULT = "default",
}

export const getBackgroundColor = (
  startTransparent: boolean,
  isAboveThreshold: boolean,
  isNavHovered: boolean,
): "transparent" | "#fff" =>
  startTransparent && !isNavHovered && isAboveThreshold ? "transparent" : "#fff";

export const getAnchorColor = (
  themeColor: string,
  backgroundColor: "transparent" | "#fff",
): string => {
  switch (backgroundColor) {
    case "transparent":
      return themeColor;
    case "#fff":
      return colors.gray800;
    default:
      return colors.gray800;
  }
};

export enum LogoFilter {
  LIGHT = "brightness(10)",
  BLACK = "brightness(0)",
  DEFAULT = "",
}

export const getNavStyles = ({
  theme,
  startTransparent,
  isAboveThreshold,
  isNavHovered,
  hideOnScrolldown,
  scrollDirection,
}: {
  theme: NavTheme;
  startTransparent: boolean;
  isAboveThreshold: boolean;
  isNavHovered: boolean;
  hideOnScrolldown?: boolean;
  scrollDirection: "up" | "down" | null;
}): {
  showNav: boolean;
  activeStyle: {
    anchorColor: string;
    logoFilter: string;
    buttonBackground: string;
    backgroundColor: string;
    boxShadow: string;
    carbonLogoSrc: string;
    transition: number;
    transitionClass: string;
  };
} => {
  const shouldHide = hideOnScrolldown && scrollDirection === "down" && !isAboveThreshold;
  const backgroundColor = getBackgroundColor(startTransparent, isAboveThreshold, isNavHovered);
  const boxShadow = backgroundColor === "transparent" ? "none" : "0 1px 4px rgba(0, 0, 0, 0.1)";
  const carbonLogoSrc = "/logo";
  const transition = 200 as 50 | 100 | 150 | 200 | 250 | 300 | 500 | 1000 | 2000;
  const transitionClass = `t-${transition}`;
  const transparentGrey = transparent(colors.gray800, 10);

  const styleLookup = {
    [NavTheme.LIGHT]: {
      anchorColor: getAnchorColor("#fff", backgroundColor),
      logoFilter: isNavHovered || !isAboveThreshold ? LogoFilter.DEFAULT : LogoFilter.LIGHT,
      buttonBackground: backgroundColor === "#fff" ? transparentGrey : "#fff",
    },
    [NavTheme.BLACK]: {
      anchorColor: getAnchorColor(colors.gray800, backgroundColor),
      logoFilter: LogoFilter.BLACK,
      buttonBackground: transparentGrey,
    },
    [NavTheme.DEFAULT]: {
      anchorColor: getAnchorColor(colors.gray800, backgroundColor),
      logoFilter: LogoFilter.DEFAULT,
      buttonBackground: transparentGrey,
    },
  };

  return {
    showNav: !shouldHide,
    activeStyle: {
      ...styleLookup[theme],
      boxShadow,
      carbonLogoSrc,
      backgroundColor,
      transition,
      transitionClass,
    },
  };
};

const checkIfAboveThreshold = (scroll: ScrollEvent, offset = 0) => scroll.y + offset >= THRESHOLD;

export const useNavState = (
  startTransparent: boolean,
  theme: NavTheme,
  isNavHovered: boolean,
): ReturnType<typeof getNavStyles> & {showBookingButton: boolean} => {
  const scroll = useScroll();

  const isMobile = useIsMobile();
  // TODO: CNSMR-785 | Bring back the show booking button conditionally
  const showBookingButton = false;

  const isAboveThreshold = checkIfAboveThreshold(scroll, isMobile ? 0 : OFFSET);

  const getNewState = useCallback(
    () => ({
      ...getNavStyles({
        theme,
        startTransparent,
        isAboveThreshold,
        isNavHovered,
        hideOnScrolldown: isMobile,
        scrollDirection: scroll.direction,
      }),
      showBookingButton,
    }),
    [
      isAboveThreshold,
      isMobile,
      isNavHovered,
      scroll.direction,
      showBookingButton,
      startTransparent,
      theme,
    ],
  );

  const initialStyle = getNewState();

  const [styleState, setStyleState] = useState(initialStyle);

  useEffect(() => {
    const newState = getNewState();
    setStyleState(state => (isEqual(state, newState) ? state : newState));
  }, [theme, startTransparent, isAboveThreshold, isNavHovered, isMobile, scroll.direction]);

  return styleState;
};
